interface Options {
  showApiSuccess?: boolean
  showApiError?: boolean
  onSuccess?: () => Record<string, unknown>
  onError?: () => Record<string, unknown>
  onFinally?: () => Record<string, unknown>
  successMsg?: string
  errorMsg?: string
}

const useApi = () => {
  const { displayError, displaySuccess } = useToast()

  const isFetching = ref(false)

  const call = async (
    callback: () => Promise<Record<string, string>>,
    options?: Options,
  ) => {
    try {
      isFetching.value = true

      if (!options) options = {}
      // Defaults
      if (!options?.showApiSuccess) {
        options.showApiSuccess = true
      }

      const res = await callback()
      const dataTypes = ['image/png', 'image/jpeg', 'image/jpg']
      if (res?.success || dataTypes.includes(res?.type)) {
        if (options?.successMsg) {
          displaySuccess(options?.successMsg)
        }
        else if (options?.showApiSuccess) {
          res.messages?.forEach((msg: string) => {
            displaySuccess(msg)
          })
        }
      }
      else {
        if (options?.showApiError) {
          res.messages?.forEach((msg: string) => {
            displayError(msg)
          })
        }
      }

      if (options?.onSuccess) {
        options.onSuccess()
      }
      return res
    }
    catch (e) {
      console.error(e)
      if (e?.response?.data?.messages && options?.showApiError) {
        e.response.data.messages.forEach((msg: string) => {
          displayError(msg)
        })
      }
      if (options?.errorMsg) {
        displayError(options.errorMsg)
      }
      if (options?.onError) {
        options.onError()
      }
    }
    finally {
      isFetching.value = false
      if (options?.onFinally) {
        options.onFinally()
      }
    }
  }

  return { call, isFetching }
}

export default useApi
